import React from 'react';
import Button from 'react-bootstrap/Button'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Share.css"
import {forEach} from "react-bootstrap/ElementChildren";

function resultToEmjoi(resultRow){
    let mapper = {
        "ABSENT": '\u{2b1c}',
        "PRESENT": "\u{1f7e7}",
        "MATCH": '\u{1f7e9}'
    }
    let emojiRow = resultRow.map((result) => {
        return(
            mapper[result]
        )
    })
    return emojiRow
}


function clickShare(results, gameNumber, gameMode="daily"){
    // Remove empty arrays from results
    let cleanResults = Array()
    results.forEach(r => {
        if (r.includes('') === false){
            cleanResults.push(r)
        }
    });

    // Map each results array to emojis
    let emjoiResults = cleanResults.map((resultRow) => {
        return(resultToEmjoi(resultRow))
    })
    let stringResults = emjoiResults.map((er) => {
        return(
            er.join("")
        )
    })
    stringResults = stringResults.join("\n")

    console.log(stringResults)
    let shareText = ""

    if (gameMode === "daily") {
        shareText = `
Word.canthonyscott.com
${gameMode} word: ${gameNumber}    ${cleanResults.length}/6

${stringResults}
`
    }
    else if (gameMode === "random"){
        shareText = `
Word.canthonyscott.com
${gameMode} word   ${cleanResults.length}/6

${stringResults}
`
    }
    console.log(shareText)
    // navigator.clipboard.writeText("TEXT TO COPY");
    navigator.share({text: shareText})
}

function ShareResult(props){

    // let state = props.state;
    let results = props.results;
    let gameNumber = props.gameNumber;
    let gameMode = props.gameMode;

    return (
        <>
            <Row className="share-btn-row">
                <Col className="justify-content-center text-center" xs={12}>
                    <Button variant="success" size="lg" className="share-btn" onClick={() => clickShare(results, gameNumber, gameMode)}>
                        SHARE <i className="bi bi-share"></i>
                    </Button>
                </Col>
            </Row>
        </>
    )

}

export default ShareResult
