import React from 'react';
import ReactDOM from 'react-dom';
import Button from 'react-bootstrap/Button'
import Cookies from 'js-cookie'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {FloatingLabel, Form} from "react-bootstrap";
import axios from "axios";
import {v4 as uuidv4} from 'uuid';

import './App.css';
import {isStringAllLetters} from "./utils";
import QwertyKeyboard from "./Qwerty";
import {SettingsModal, WinnerModal, GameOverModal, StatsModal, XAuthModal} from "./Modals"
import MessageBoard from "./Message"
import TokenManger from "./TokenManger";



function LetterBox(props){
    return (
        <Col className={props.style}>
            {props.value}
        </Col>
    )
}

class GuessRow extends React.Component {

    render(){
        const guess = this.props.current_guess;
        const result = this.props.result;
        const shake = this.props.shake;
        const letters = guess.map((letter, index) => {
            // Create a style from base class and our result
            const addlStyle = result[index].toString().toLowerCase()
            const flip = addlStyle ? " flip " : ""
            const s = shake ? "shake" : ""
            const letterStyle = "letter-box " + result[index].toString().toLowerCase() + flip + s;
            return (
                <LetterBox key={index} value={letter} style={letterStyle}/>
            );
        });

        return (
            <Row md="6" sm="6" xs="6" className="justify-content-center">
                {letters}
            </Row>
        )
    }
}


class Board extends React.Component{

    render() {
        const guesses = this.props.guesses;
        const results = this.props.results;
        const shake = this.props.shake;
        const rows = guesses.map((guess, index) => {
            return(
                <GuessRow key={index} current_guess={guess} result={results[index]} shake={shake}/>
            )
        })
        return(
            <div className="board">
                {rows}
            </div>
        )
    }
}

class BoardHeader extends React.Component{
    render() {
        return (
            <Row className="game-header">
                <Col xs={{span: 6, offset: 3}} className="text-center">
                    <h1>Word</h1>
                </Col>
                <Col xs={{span: 3}}>
                    <Row>
                        <Col xs="6"><i className="bi bi-file-bar-graph" onClick={() => this.props.onClickStats()}/></Col>
                        <Col xs="6"><i className="bi bi-gear" onClick={() => this.props.onClickSettings()}/></Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

class Game extends React.Component {

    static WORDSIZE=5;
    static ROWNUMBER=6;
    // static URLBASE = "https://word.home.canthonyscott.com"
    // static URLBASE = "http://127.0.0.1:8000"
    static URLBASE = process.env.REACT_APP_URLBASE;

    constructor(props) {
        super(props);
        this.state = {
            guessNumber: 0,
            letterNumber: 0,
            inputText: "",
            currentGuess: [],
            game: "daily",
            guesses: Array(Game.ROWNUMBER).fill(Array(Game.WORDSIZE).fill("")),
            results: Array(Game.ROWNUMBER).fill(Array(Game.WORDSIZE).fill("")),
            stats: {"daily_played": 0, "daily_win_pct": "0%", "daily_streak": 0, "daily_max_streak": 0, "guessDist": []},
            letterMatches: {},
            isWinner: false,
            announceWinner: false,
            announceGameOver: false,
            openSettings: false,
            openStats: false,
            playerid: undefined,
            gameOver: false,
            shake: false,
            openXAuth: false

        }
        this.tokenManager = new TokenManger();
    }

    componentDidMount() {
        const playerid  = this.getOrSetPlayerId();
        this.initialSync(playerid);
        // If refresh token exists, start auto-updating the access token
        if (this.tokenManager.checkIfTokensExist()) {
            // Get a fresh one at the start
            this.tokenManager.getNewAccessToken();
            this.tokenManager.updatePlayerId();
            this.interval = setInterval(() => this.tokenManager.getNewAccessToken(), 30000);
        }
        // refresh token does not exist, try to prompt user to utilize it
        else {
            this.promptForAccountLink();
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    promptForAccountLink(){
        // check if ignore cookie is still active
        let ignoreCookie = Cookies.get("ignore_xauth");
        if (ignoreCookie != "true"){
            this.setState({
                openXAuth: true
            })
        }
    }

    setIgnoreCookie(){
        console.log("Setting ignore cookie.")
        Cookies.set("ignore_xauth", "true", {expires: 3});
        this.setState({openXAuth: false});
    }


    getOrSetPlayerId(){
        // Player id generated using uuid v4 and stored as a long-expiring cookie. This will be sent to server.
        let playerid = Cookies.get('playerid');
        if (playerid === undefined) {
            playerid = uuidv4();
            Cookies.set('playerid', playerid, {expires: 50000});
        }
        this.setState({
            playerid: playerid
        });

        return playerid
    }

    saveState(){
        const url = Game.URLBASE + "/wordapi/save";
        const csrftoken = Cookies.get('csrftoken');
        const outputData = JSON.stringify({state: this.state})

        axios.post(
            url,
            outputData,
            {headers: {'Content-Type': 'application/json', "X-CSRFToken": csrftoken}}
        )
            .then((response) => {
                console.log("State saved")
                console.log(response.data);
            })
            .catch(function (error){
                console.log("axios error");
                console.log(error)
            })
    }
    initialSync(playerid){
        const url = Game.URLBASE + "/wordapi/init"
        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = 'X-CSRFToken'
        axios.defaults.withCredentials = true
        axios.get(url, {params: { playerid: playerid } }
        )
            .then((response) => {
                console.log(response);
                const sessionCookie = Cookies.get()
                console.log('Cookies', sessionCookie)
                if (response.data.lastState){
                    // delete the openXAuth key
                    delete response.data.lastState.openXAuth;
                    this.setState(response.data.lastState);

                    console.log("Loaded existing state");
                } else {
                    console.log("No state to load")
                }
                this.setState({
                    dailyGameNumber: response.data.daily_game_number
                });

            })
            .catch(function (error){
                console.log("axios error on init sync");
                console.log(error)
            })

    }

    processApiResponse(responseData, guess, result){
        console.log("Process API response")
        if (responseData.validGuess === true){
            const results = this.state.results.slice();
            const guessNumber = responseData.serverState.guessNumber;
            results[guessNumber] = responseData.result;
            const newGuessNumber = guessNumber + 1;
            this.setState({
                results: results,
                guessNumber: newGuessNumber
            })

            // Handle winner or loser
            if (responseData.winner){
                setTimeout(() => {  this.activateWin(); }, 1250);
            } else if (responseData.gameOver) {
                setTimeout(() => { this.activateGameOver(responseData.word); }, 1250)
            }

            this.parseLetterMatches(guess, result);

        }
        // Handle a invalid guess i.e. not a real word
        else if (responseData.validGuess === false){
            this.setState({
                shake: true,
                message: "I don't know that word"
            });
            setTimeout(() => this.setState({shake: false, message: ""}), 1000);
        }
    }
    parseLetterMatches(guess, result){
        let letterMatches = Object.assign({}, this.state.letterMatches);
        console.log(letterMatches);
        for (let i = 0; i < guess.length; i ++){
            // check if letter has been seen
            if (guess[i] in letterMatches){
                // Always update if a match
                if (result[i] === "MATCH"){letterMatches[guess[i]] = result[i]}
            } else {
                letterMatches[guess[i]] = result[i];
            }
        }
        this.setState({
            letterMatches:  letterMatches
        }, () => {this.saveState()} );
        console.log(letterMatches);
    }
    checkGuess(guess){
        const url = Game.URLBASE + "/wordapi/check";
        const outputData = JSON.stringify({state: this.state, guess: guess})
        console.log("Checking guess with server...")
        // POST data to api
        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = 'X-CSRFToken'
        axios.defaults.withCredentials = true
        const csrftoken = Cookies.get('csrftoken');

        axios.post(
            url,
            outputData,
            {headers: {'Content-Type': 'application/json', "X-CSRFToken": csrftoken}}
        )
            .then((response) => {
                const result = response.data.result;
                console.log(response.data)
                console.log(guess);
                console.log(result);
                this.processApiResponse(response.data, guess, result);
                // this.parseLetterMatches(guess, result);
        })
            .catch(function (error){
                console.log("axios error");
                console.log(error)
        })
    }

    getPlayerStats(){
        const url = Game.URLBASE + "/wordapi/stats"
        axios.get(url)
            .then((response) => {
                console.log(response.data);
                this.setState({
                    stats: response.data.player_stats
                })
            })
    }

    handleStatsOpen(){
        this.getPlayerStats()
        this.setState({
            openStats: true
        })
    }

    padArray(array, length){
        let newArray = array.slice();
        while (newArray.length < length){
            newArray.push("");
        }
        return newArray
    }

    handleQwertyKeyboard(e){
        const newLetter = e.target.value;
        let guesses = this.state.guesses.slice();
        let guessNumber = this.state.guessNumber;
        let currentGuess = this.state.currentGuess.slice()

        // If a letter was typed
        if (newLetter !== 'ent' && newLetter !== 'bksp' && currentGuess.length < 5 && this.state.isWinner !== true && this.state.gameOver != true){
            currentGuess.push(newLetter);
            let paddedGuess = this.padArray(currentGuess, Game.WORDSIZE)
            guesses[guessNumber] = paddedGuess;
            this.setState({
                currentGuess: currentGuess,
                guesses: guesses
            })

        } else if (newLetter === 'bksp') {
            currentGuess.pop();
            let paddedGuess = this.padArray(currentGuess, Game.WORDSIZE)
            guesses[guessNumber] = paddedGuess;
            this.setState({
                guesses: guesses,
                currentGuess: currentGuess
            })
        } else if (newLetter === "ent" && currentGuess.length === Game.WORDSIZE) {
            this.checkGuess(currentGuess);
            // guessNumber = guessNumber + 1;
            currentGuess = [];
            this.setState({
                // guessNumber: guessNumber,
                currentGuess: currentGuess
            })
        }
    }
    activateWin(){
        this.setState({
            announceWinner: true,
            isWinner: true
        }, () => {this.saveState()});
    }
    activateGameOver(word){
        this.setState({
            gameOver: true,
            announceGameOver: true,
            word: word
        }, () => {this.saveState()});
    }

    toggleGameMode(current_mode){
        if (current_mode == "daily"){
            this.setState({game: "random"})
        } else {
            this.setState({game: "daily"})
        }
    }

    // resets the game for continued play in random mode
    restartGame(gamemode){
        console.log("Restarting game");
        this.setState({
            guessNumber: 0,
            letterNumber: 0,
            inputText: "",
            currentGuess: [],
            game: gamemode,
            guesses: Array(Game.ROWNUMBER).fill(Array(Game.WORDSIZE).fill("")),
            results: Array(Game.ROWNUMBER).fill(Array(Game.WORDSIZE).fill("")),
            letterMatches: {},
            isWinner: false,
            announceWinner: false,
            announceGameOver: false,
            openSettings: false,
            gameOver: false,
            message: "",
        });

    }

    render() {
        return (
            <Container className="main-page">
                <BoardHeader onClickSettings={() => {this.setState({openSettings: true})}}
                             onClickStats={() => this.handleStatsOpen()}
                />
                <Board
                    guesses={this.state.guesses.slice()}
                    current_guess={this.state.guesses[this.state.guessNumber]}
                    results={this.state.results}
                    shake={this.state.shake}
                />
                <MessageBoard
                    message={this.state.message}
                />
                <QwertyKeyboard
                    onClick={(e) => this.handleQwertyKeyboard(e)}
                    letterMatches={Object.assign({}, this.state.letterMatches)}
                />
                <WinnerModal
                    show={this.state.announceWinner}
                    handleWinnerClose={() => {this.setState({announceWinner: false})}}
                    startRandomMode={() => {this.restartGame("random")}}
                    results={this.state.results}
                    gameNumber={this.state.dailyGameNumber}
                    gameMode={this.state.game}
                />
                <GameOverModal
                    show={this.state.announceGameOver}
                    handleGameOverClose={() => {this.setState({announceGameOver: false})}}
                    startRandomMode={() => {this.restartGame("random")}}
                    wordRow={<GuessRow letters={this.state.word} current_guess={this.state.word} result={Array(5).fill("")} />}
                    results={this.state.results}
                    gameNumber={this.state.dailyGameNumber}
                    gameMode={this.state.game}
                />
                <SettingsModal
                    show={this.state.openSettings}
                    game={this.state.game}
                    handleClose={() => {this.setState({openSettings: false})}}
                    handleSave={() => {this.restartGame(this.state.game)}}
                    resetPlayerId={() => {Cookies.remove("playerid")}}
                    gameToggle={(game) => this.toggleGameMode(game)}
                />
                <StatsModal
                    show={this.state.openStats}
                    handleClose={() => this.setState({openStats: false})}
                    stats = {this.state.stats}
                />
                <XAuthModal
                    show={this.state.openXAuth}
                    handleClose={() => this.setState({openXAuth: false})}
                    handleIgnore={() => this.setIgnoreCookie()}
                />
            </Container>
        )
    }
}

export {GuessRow};
export default Game;