import React from "react";
import {Redirect} from 'react-router-dom';
import axios from "axios";
import Game from "./App";
import Cookies from "js-cookie";


class GoogleCallback extends React.Component {

    redirectToApp(){
        window.location = "/";
    }

    linkTokenWithPlayerID() {
        function performLink(){
            axios.post(
                LINKURL,
                outputData,
                {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access_token}}
            ).then((r) => {
                console.log(r);
                window.location = "/";
            }).catch(function (e) {
                console.log("ERROR LINKING PLAYERID");
                console.log(e)
            })
        }

        var LINKURL = Game.URLBASE + "/wordapi/link";
        var playerid = Cookies.get("playerid");
        var outputData = JSON.stringify({playerid: playerid});
        var access_token = Cookies.get("access");

        // Check if user already has a playerid
        axios.get(
            LINKURL,
            {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access_token}}
        ).then((r) => {
            if (r.data.exists == true){
                console.log(r)
                console.log("player exists");
                Cookies.set("playerid", r.data.playerid, {expires: 50000});
                window.location = "/"
            } else {
                console.log("Player does not exist");
                console.log(r);
                performLink();
            }
        }).catch(function(e) {
            console.log("ERROR")
            console.log(e);
        })


    }


    completeOAuth() {
        const callbackurl = window.location.href;

        axios.post(
            Game.URLBASE + "/wordapi/xauth/google/callback/",
            JSON.stringify({callbackuri: callbackurl}),
            {headers: {'Content-Type': 'application/json'}}
        )
            .then((response) => {
                console.log(response);
                // Save tokens as cookies
                var resp_data = response.data;
                var access_token = resp_data['access'];
                var refresh_token = resp_data['refresh'];
                Cookies.set('access', access_token);
                Cookies.set('refresh', refresh_token, {expires: 5});
                // Link with playerid, which redirects to app
                this.linkTokenWithPlayerID();
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    render() {
        this.completeOAuth();
        return "Redirecting..."
    }
}

export {GoogleCallback}