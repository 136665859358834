import React from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Form} from "react-bootstrap";
import Table from "react-bootstrap/Table"
import {GuessRow} from "./App";
import ShareResult from "./Share";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList} from 'recharts';
import Game from "./App";
import './Modals.css';
import Cookies from "js-cookie";

function WinnerModal(props) {
    return (
        <>
            <Modal
                show={props.show}
                backdrop="static"
                keyboard={false}
                dialogClassName="shadow-lg p-3 mb-5 bg-white rounded"
            >
                <Modal.Header>
                    <Modal.Title>Winner!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Congratulations!</p>
                    <p>Come back tomorrow for another daily word or
                        continue playing in random mode for the day.</p>
                    <ShareResult results={props.results} gameNumber={props.gameNumber} gameMode={props.gameMode}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.handleWinnerClose()}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => props.startRandomMode()}>
                        Random Word
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

function GameOverModal(props) {
    return (
        <>
            <Modal
                show={props.show}
                backdrop="static"
                keyboard={false}
                dialogClassName="shadow-lg p-3 mb-5 bg-white rounded"
            >
                <Modal.Header>
                    <Modal.Title>Better luck next time!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Sorry! You didn't get it. The word you were trying to guess was</p>
                    <div>{props.wordRow}</div>
                    <ShareResult results={props.results} gameNumber={props.gameNumber} gameMode={props.gameMode}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.handleGameOverClose()}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => props.startRandomMode()}>
                        Random Word
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}


function WarningModal(props) {
    return (
        <>
            <Modal
                show={props.show}
                backdrop="static"
                keyboard={false}
                dialogClassName="shadow-lg p-3 mb-5 bg-white rounded"
            >
                <Modal.Header>
                    <Modal.Title>Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Resetting your playerid will cause loss of all your stats. This cannot be undone.</p>
                    <p>Are you sure?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => props.goBack()}>
                        Go back
                    </Button>
                    <Button variant="danger" onClick={() => props.resetPlayerId()}>
                        I am sure
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}

class SettingsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showWarning: false,
            changed: false
        }
    }

    toggleChanged() {
        this.setState({
            changed: true
        });
    }

    render() {
        return (
            <>
                <Modal
                    show={this.props.show}
                    backdrop="static"
                    keyboard={false}
                    dialogClassName="shadow-lg p-3 mb-5 bg-white rounded"
                >
                    <Modal.Header>
                        <Modal.Title>Settings</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="settings-row">
                            <Col xs={5}>Game mode: </Col>
                            <Col xs={3}>
                                <Form>
                                    <Form.Check
                                        disabled
                                        type="switch"
                                        id="gamemode-switch"
                                        onClick={() => {
                                            this.props.gameToggle(this.props.game);
                                            this.toggleChanged();
                                        }}
                                    />
                                </Form>
                            </Col>
                            <Col xs={4}><p className="capitalize-me">{this.props.game}</p></Col>
                        </Row>
                        {/*<Row className="settings-row">*/}
                        {/*    <Col xs={12}>Test</Col>*/}
                        {/*</Row>*/}
                        {/*<Row className="settings-row">*/}
                        {/*    <Col xs={12}>Test</Col>*/}
                        {/*</Row>*/}
                        {/*<Row className="settings-row">*/}
                        {/*    <Col xs={12}>Test</Col>*/}
                        {/*</Row>*/}
                        <Row className="settings-row danger-zone text-center">
                            <Col xs={12} className="center-text">
                                <p>Danger Zone</p>
                            </Col>
                            <Col xs={12}>
                                <Button variant="outline-danger" size="sm"
                                        onClick={() => this.setState({showWarning: true})}>Reset Player Id</Button>
                            </Col>
                        </Row>
                        <Row className="">
                            <Col xs={12} className="info-text">
                                <p>App version: {process.env.REACT_APP_VERSION}</p>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.props.handleClose()}>
                            Close
                        </Button>
                        <Button disabled={!this.state.changed} variant="primary"
                                onClick={() => this.props.handleSave()}>
                            Save
                        </Button>

                    </Modal.Footer>
                </Modal>
                <WarningModal
                    show={this.state.showWarning}
                    goBack={() => this.setState({showWarning: false})}
                    resetPlayerId={() => {
                        this.props.resetPlayerId();
                        this.setState({showWarning: false});
                        window.location.reload()
                    }}
                />
            </>

        )

    }

}

const demoData =[
    {
        guess: "1",
        n: 1,
    },
    {
        guess: "2",
        n: 3,
    },
    {
        guess: "3",
        n: 5,
    },
    {
        guess: "4",
        n: 10,
    },
    {
        guess: "5",
        n: 9,
    },
    {
        guess: "6",
        n: 3,
    },
]

function StatsModal(props) {
        return (
            <>
                <Modal
                    show={props.show}
                    backdrop="static"
                    keyboard={false}
                    dialogClassName="shadow-lg rounded modal-dialog-centered"
                >
                    <Modal.Header >
                        <Modal.Title>Player stats</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12} className="text-center">
                                Daily Games
                            </Col>
                            <Col xs={12}>
                                <Table size="sm" className="text-center">
                                    <thead className="stats-head">
                                    <tr>
                                        <th>Played</th>
                                        <th>Win %</th>
                                        <th>Current Streak</th>
                                        <th>Max Streak</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{props.stats.daily_played}</td>
                                        <td>{props.stats.daily_win_pct}</td>
                                        <td>{props.stats.daily_streak}</td>
                                        <td>{props.stats.daily_max_streak}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}><p className="text-center">Guess distribution</p></Col>
                            <Col>
                                <ResponsiveContainer  minWidth="100%" minHeight={200}>
                                    <BarChart
                                        width={100}
                                        height={200}
                                        data={props.stats.guessDist}
                                        margin={{
                                            top:0,
                                            right:30,
                                            left:0,
                                            bottom:1
                                        }}
                                        layout="vertical"
                                    >
                                        <CartesianGrid horizontal={false} vertical={false}/>
                                        <YAxis dataKey="guess" type="category"/>
                                        <XAxis type="number" hide={true}/>
                                        <Bar dataKey="n" fill="#262469" isAnimationActive={false}>
                                            <LabelList dataKey="n" position="insideRight" style={{fill: "white"}} offset={2}/>
                                        </Bar>

                                    </BarChart>
                                </ResponsiveContainer>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className="text-center">
                                Random Games
                            </Col>
                            <Col xs={12}>
                                <Table size="sm" className="text-center">
                                    <thead className="stats-head">
                                    <tr>
                                        <th>Played</th>
                                        <th>Win %</th>
                                        <th>Current Streak</th>
                                        <th>Max Streak</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{props.stats.random_played}</td>
                                        <td>{props.stats.random_win_pct}</td>
                                        <td>{props.stats.random_streak}</td>
                                        <td>{props.stats.random_max_streak}</td>
                                    </tr>
                                    </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.handleClose()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


function XAuthModal(props) {
    let redirect_uri = window.location.href + "callback/google/";
    let loginURL = Game.URLBASE + "/wordapi/xauth/google?redirect_url=" + redirect_uri;

    return (
        <>
            <Modal
                show={props.show}
                backdrop="static"
                keyboard={false}
                dialogClassName="shadow-lg rounded modal-dialog-centered"
            >
                <Modal.Header>
                    <Modal.Title>Save your progress</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12} className="text-center">
                            <p>Save your stats and progress!</p>
                            <p>Connect or retrieve your linked account below</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="text-center">
                            <a className="btn" href={loginURL} role="button">
                                <img width="200px"
                                     alt="Google sign-in"
                                     src="https://developers.google.com/static/identity/images/branding_guideline_sample_nt_rd_lg.svg"/>
                                {/*Login with Google*/}
                            </a>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.handleClose()}>
                        Close
                    </Button>
                    <Button variant="light" onClick={() => props.handleIgnore()}>
                        Remind me later
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export {WinnerModal, GameOverModal, SettingsModal, StatsModal, XAuthModal};