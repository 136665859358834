import React from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Game from "./App";

class TokenManger {

    constructor() {
        this.refresh_token = null;
    }

    updatePlayerId(){
        var LINKURL = Game.URLBASE + "/wordapi/link";
        var access_token = Cookies.get("access");

        // Check if user already has a playerid
        axios.get(
            LINKURL,
            {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access_token}}
        ).then((r) => {
            if (r.data.exists == true){
                console.log(r)
                console.log("player exists");
                Cookies.set("playerid", r.data.playerid, {expires: 50000});
                console.log("Set playerid cookie");
            } else {
                console.log("Player does not exist");
            }
        }).catch(function(e) {
            console.log("ERROR");
            console.log(e);
        })

    }


    checkIfTokensExist(){
        var refresh_token = Cookies.get("refresh");
        if (refresh_token == undefined){
            console.log("No refresh token found");
            return false
        } else {
            console.log("Refresh token exists");
            this.refresh_token = refresh_token;
            return true
        }

    }

    getNewAccessToken(){
        var refresh_token = this.refresh_token;
        var outputData = JSON.stringify({refresh: refresh_token});
        axios.post(
            Game.URLBASE + "/wordapi/token/refresh/",
            outputData,
            {headers: {'Content-Type': 'application/json'}}
        ). then((r) => {
            console.log(r);
            Cookies.set("access", r.data['access']);
        }).catch(function(error) {
            console.log("ERROR");
            console.log(error.response);
            console.log(error.response.status);
            console.log(error);
            console.log("Removing access and refresh tokens");
            Cookies.remove("access");
            Cookies.remove("refresh");
        })
    }
}

export default TokenManger;