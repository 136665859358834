import React from 'react';
import './App.css';
import './Qwerty.css'


function Key(props) {
    let matchClass = ""
    try{
        matchClass = props.letterMatches[props.value].toLowerCase();
    } catch (error){
        matchClass = "error"
    }
    // let matchClass = ""
    let keyClass = "keyboard-key " + props.value + " " + matchClass;
    let label = props.letter
    if (props.letter === "bksp"){
        label = <i className='bi bi-backspace'></i>
    } else if (props.letter === "ent") {
        label = <i className="bi bi-arrow-return-right"></i>
    }
    return (
        <button className={keyClass} value={props.value} onClick={(e) => props.onClick(e)}>
            {label}
        </button>
    )
}

class QwertyKeyboard extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {testing: "testing"}
        this.layout = [
            ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
            ["A", "S", "D", "F", "G", "H", "J", "K", "L"],
            ["ent", "Z", "X", "C", "V", "B", "N", "M", "bksp"]
        ]
    }

    render() {
        return (
            <div className="keyboard-box">
                <div className="keyboard-row">
                    {this.layout[0].map((letter, index) => {
                        return (
                            <Key key={index} value={letter} letter={letter} onClick={this.props.onClick}
                                 letterMatches={this.props.letterMatches}/>
                        )
                    })}
                </div>
                <div className="keyboard-row">
                    {this.layout[1].map((letter, index) => {
                        return (
                            <Key key={index} value={letter} letter={letter} onClick={this.props.onClick}
                                 letterMatches={this.props.letterMatches}/>
                        )
                    })}
                </div>
                <div className="keyboard-row">
                    {this.layout[2].map((letter, index) => {
                        return (
                            <Key key={index} value={letter} letter={letter} onClick={this.props.onClick}
                                 letterMatches={this.props.letterMatches}/>
                        )
                    })}
                </div>


            </div>
        )
    }

}

export default QwertyKeyboard;