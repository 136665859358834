import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Message.css"

function MessageBoard(props){
    return(
        <Row className="text-center">
            <Col xs={12}>
                <p className="message-block">{props.message}</p>
            </Col>
        </Row>
    )

}

export default MessageBoard